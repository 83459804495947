<template>
  <div>
    <ProductPopup
      :data="selectedProduct"
      :get-products="getAndUpdateProducts"
    />
    <ModalProductMobileView :product="mobileViewProduct" />
    <b-card
      :header="$i18n.t('navigation.products-review')"
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <FiltersAndSearch
        :searchKey.sync="searchKey"
        :primaryStatus.sync="status"
        :primaryStatusFields="productStatusList"
        :startDate.sync="startDate"
        :endDate.sync="endDate"
        :priceSort.sync="priceSort"
        :sortOrder.sync="sortOrder"
        disableActiveOnly
      />
      <div v-if="false">
        <ProductTypeSwitch :product-type-switch.sync="productTypeSwitch" />
      </div>
      <b-table
        sticky-header
        :fields="fields"
        :items="productsList"
        :busy="isTableBusy"
        bordered
        hover
        responsive
        class="shadow-sm rounded products-table"
        thead-tr-class="order-main-thead"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        small
        @row-clicked="onRowClick"
      >
        <template #cell(nameGeo)="data">
          <div class="d-flex align-items-center">
            <div class="d-flex flex-column">
              <span>{{ data.item.nameGeo }}</span>
              <span>{{ data.item.nameEng }}</span>
            </div>
            <div
              v-if="data.item.serviceType === 4"
              class="ml-auto border border-info rounded-pill d-flex align-items-center"
            >
              <span
                class="text-info"
                style="font-size: 11px; padding: 0 0.5rem"
              >
                moodie
              </span>
            </div>
          </div>
        </template>
        <template #cell(action)="data">
          <b-button
            class="btn-icon"
            variant="flat-success"
            @click="getMobileView(data.item)"
          >
            <feather-icon size="16" icon="EyeIcon" />
          </b-button>
        </template>
      </b-table>
      <Pagination
        :fetch-data="getAndUpdateProducts"
        :total-rows="totalRows"
        :take.sync="take"
        :skip.sync="skip"
      />
    </b-card>
  </div>
</template>

<script>
import { BCard, BTable, BButton } from "bootstrap-vue";
import moment from "moment";
import { mapActions, mapState } from "vuex";
import Swal from "sweetalert2";
import ModalProductMobileView from "@/views/products/components/ModalProductMobileView.vue";
import ProductPopup from "./ProductPopup.vue";
import "vue-good-table/dist/vue-good-table.css";
import Pagination from "../components/Pagination/Pagination.vue";
import ProductTypeSwitch from "./components/ProductTypeSwitch.vue";
import FiltersAndSearch from "../components/FiltersAndSearch/FiltersAndSearch.vue";

export default {
  components: {
    BCard,
    BTable,
    ProductPopup,
    Pagination,
    ProductTypeSwitch,
    ModalProductMobileView,
    BButton,
    FiltersAndSearch,
  },
  filters: {
    formatDate(value) {
      return moment(String(value)).format("HH:mm  MM/DD/YYYY");
    },
  },
  data() {
    return {
      productTypeSwitch: "pending",
      pageLength: 10,
      dir: false,
      productsList: [],
      action: null,
      selectedProduct: null,
      selectedAlghorithmDescriptors: null,
      mobileViewProduct: null,
      // pagination
      take: 20,
      skip: 0,
      totalRows: 0,
      // filters
      sortOrder: 2,
      id: "",
      searchKey: "",
      status: "",
      activeOnly: true,
      endDate: "",
      startDate: "",
      priceSort: false,
    };
  },
  computed: {
    ...mapState("products", [
      "products",
      "algorithmDescriptors",
      "isTableBusy",
    ]),
    fields() {
      return [
        {
          label: "Action",
          key: "action",
        },
        {
          label: "Name",
          key: "nameGeo",
          tdClass: "nameColumn",
        },
        {
          key: "price",
          label: "Price",
          sortable: false,
          formatter: (value, key, item) => `${item.price.toFixed(2)} ₾`,
        },
        {
          key: "price",
          label: "Price",
          sortable: false,
          formatter: (value, key, item) => `${item.price.toFixed(2)} ₾`,
        },
        {
          key: "configurationCount",
          label: "Count",
          formatter: (value, key, item) => item?.productConfigurations.length ? item?.configurationCount : item?.count,
        },
        {
          key: "commissionFeePercent",
          label: "Comission",
        },
        {
          label: "Created at",
          key: "createdAt",
          sortable: false,
        },
        {
          label: this.$i18n.t("products.end_at"),
          key: "activeTo",
          sortable: false,
        },
        {
          label: "Vendor",
          key: "vendor",
          formatter: (value) =>
              `${value.brandNameGeo} / ${value.brandNameEng}`,
          sortable: false,
        },
        {
          label: "Vendor ID",
          key: "vendor.id",
          sortable: false,
        },
        {
          label: "Category",
          key: "productCategory",
          formatter: (value) =>
            `${value.productCategoryNameGeo} / ${value.productCategoryNameEng}`,
          sortable: false,
        },
        {
          label: "Sub Category",
          key: "productSubCategory",
          formatter: (value) =>
            `${value.productSubCategoryNameGeo} / ${value.productSubCategoryNameEng}`,
          sortable: false,
        },
        {
          label: "Generic Category",
          key: "productGenericCategory",
          formatter: (value) =>
            `${value.productGenericCategoryNameGeo} / ${value.productGenericCategoryNameEng}`,
          sortable: false,
        },
        {
          label: "SKU",
          key: "upcOrSku",
        },
      ];
    },
    productStatusList() {
      return {
        1: this.$i18n.t("orders.pending"),
        2: this.$i18n.t("orders.rejected"),
      };
    },
  },
  watch: {
    searchKey() {
      this.getAndUpdateProducts();
    },
    activeOnly() {
      this.getAndUpdateProducts();
    },
    status() {
      this.getAndUpdateProducts();
    },
    id() {
      this.getAndUpdateProducts();
    },
    startDate() {
      this.getAndUpdateProducts();
    },
    endDate() {
      this.getAndUpdateProducts();
    },
    priceSort() {
      this.getAndUpdateProducts();
    },
    take() {
      this.getAndUpdateProducts();
    },
    sortOrder() {
      this.getAndUpdateProducts();
    },
  },
  created() {
    this.getAndUpdateProducts();
  },
  methods: {
    ...mapActions("products", [
      "getProducts",
      "deleteProductById",
      "setProductStatus",
      "getAlgorithmDescriptors",
    ]),
    swalConfirmation(type = "reject") {
      return Swal.fire({
        title: "Are you sure?",
        text: `You will ${type} this product!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#d33",
        confirmButtonText: `Yes, ${type}!`,
      });
    },
    async getAndUpdateProducts() {
      try {
        await this.getProducts({
          skip: this.skip,
          take: this.take,
          searchKey: this.searchKey,
          activeOnly: this.activeOnly,
          sortOrder: this.sortOrder,
          status: this.status,
          id: this.id,
          startDate: this.startDate,
          endDate: this.endDate,
          priceSort: this.priceSort,
          productType: "review-products",
        });
        this.productsList = this.products?.products;
        this.productTypeSwitch = "pending";
        this.totalRows = this.products?.count;
      } catch (error) {
        console.log("error", error);
      }
    },
    async removeProductButton(prod) {
      const { id } = prod;
      const result = await this.swalConfirmation("delete");
      if (result.isConfirmed) {
        try {
          await this.deleteProductById(id);
          this.getProducts({
            skip: this.skip,
            take: this.take,
            searchKey: "",
            productType: "review-products",
          });
        } catch (error) {
          console.log(error);
        }
      }
    },
    onRowClick(item) {
      this.selectedProduct = item;
      this.$bvModal.show("product-show-modal");
    },
    getMobileView(prod) {
      this.mobileViewProduct = prod;
      this.$bvModal.show("product-mobile-modal");
    },
  },
};
</script>
<style lang="scss" scoped>
.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
  }
}
.custom-search {
  display: flex;
  justify-content: flex-end;
  @media (max-width: 600px) {
    width: 100%;
    justify-content: flex-start;
  }
}
.form-group {
  @media (max-width: 600px) {
    width: 100%;
  }
}
.scrollme {
  overflow-x: auto;
  max-height: 200px;
  max-width: 50%;
}
.products-table th,
.products-table td {
  // min-width: 200px ;
  white-space: nowrap;
}
</style>
